















import { Vue, Component, Prop } from 'vue-property-decorator';
import { format } from 'date-fns';

@Component
export default class TimelineItem extends Vue {
  @Prop()
  readonly icon!: string;

  @Prop()
  readonly color!: string;

  @Prop()
  readonly time!: string;

  get formattedDate(): string {
    return format(this.time, this.$tc('_.date_format'));
  }

  get formattedTime(): string {
    return format(this.time, 'HH:mm');
  }
}
