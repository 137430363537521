

















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { Event } from '@/lib/statusevents';
import { Timeline, TimelineItem } from '@/components/timeline';

@Component({
  components: {
    TimelineItem,
    Timeline,
  },
})
export default class StatusTimeline extends Vue {
  @Prop({ required: true })
  readonly eventList!: Event[];
}
