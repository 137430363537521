export enum EventType {
  Book,
  Mail,
  Sepa,
  Collection,
  Payment,
  Remind,
  Recurring,
  Quotation,
  Relation,
}
