













































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import { Timeline, TimelineItem } from '@/components/timeline';
import { InvoiceCommentsStatusItem } from '@/lib/services/invoices';
import { unwrapError } from '@/lib/helpers';
import { format } from 'date-fns';
import { DATE_FORMAT_ISO8601 } from '@/lib/constants';
import { InvoiceCommentService } from '@/lib/services';

@Component({
  components: {
    Timeline,
    TimelineItem,
  },
})
export default class CommentPanel extends Vue {
  @Prop({ required: true })
  readonly comments!: InvoiceCommentsStatusItem[];
  @Prop({ required: true })
  readonly invoiceId!: string;

  loading: boolean = false;
  newComment: string = '';

  @Getter('settings/userName')
  readonly currentUserName!: string;
  @Getter('auth/user')
  readonly currentUserId!: number;

  $refs: {
    timelineContainer: HTMLDivElement;
    addCommentField: HTMLInputElement;
  };

  mounted(): void {
    this.afterComment();
  }

  async addComment(): Promise<void> {
    if (!this.newComment) return;

    this.loading = true;
    try {
      await InvoiceCommentService.create(+this.invoiceId, this.newComment);
      this.comments.push({
        user: {
          id: this.currentUserId,
          name: this.currentUserName,
        },
        time: format(new Date(), DATE_FORMAT_ISO8601),
        message: this.newComment,
      });
      this.newComment = '';
      Vue.nextTick(() => this.afterComment());
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.create.comment'),
        unwrapError(e),
      );
    }
    this.loading = false;
  }

  afterComment(): void {
    this.$refs.timelineContainer.scrollTop =
      this.$refs.timelineContainer.scrollHeight;
    this.$nextTick(() => this.$refs.addCommentField.focus());
  }
}
