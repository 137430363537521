






























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { InvoiceService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';
import { InvoiceStatus } from '@/lib/services/invoices';
import InvoiceModalSidebar from '@/components/InvoiceModalSidebar.vue';

@Component({
  components: {
    InvoiceModalSidebar,
  },
})
export default class ViewInvoiceModal extends Vue {
  @Prop()
  readonly invoiceId!: string;

  @Prop()
  readonly status: InvoiceStatus | null;

  @Prop({ default: 'Viewer' })
  title: string;

  @Prop({ default: true })
  showSidebar: boolean;

  blobSrc: string = '';

  async mounted(): Promise<void> {
    if (!this.invoiceId) return;

    try {
      const blob = await InvoiceService.view(this.invoiceId);
      this.setBlob(blob);
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.download.invoice', 1),
        unwrapError(e),
      );
    }
  }

  async beforeDestroy(): Promise<void> {
    if (this.blobSrc) {
      URL.revokeObjectURL(this.blobSrc);
    }
  }

  public setBlob(blob: Blob): void {
    if (this.blobSrc) {
      URL.revokeObjectURL(this.blobSrc);
    }

    this.blobSrc = URL.createObjectURL(blob);
  }

  close(): void {
    this.$emit('close');
  }
}
