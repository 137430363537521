













import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';

@Component({
  components: { Popover },
})
export default class ViewInvoiceModal extends Vue {
  @Prop()
  icon: string;

  @Prop()
  caption: string;
}
