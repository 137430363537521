import { InvoiceMailStatusEvent, InvoiceStatus } from '@/lib/services/invoices';
import { Event, EventType } from '.';

import { parse } from 'date-fns';

const STATUS_TYPE_MAP: { [key: string]: { type: EventType; title: string } } = {
  sepa: { type: EventType.Sepa, title: 'Generated SEPA' },
  payment: { type: EventType.Payment, title: 'Marked as paid' },
  recurring: { type: EventType.Recurring, title: 'Recurring' },
  quotation: { type: EventType.Quotation, title: 'Quotation' },
};

export function generateEventList(status: InvoiceStatus): Event[] {
  const events: Event[] = [];

  // Booking
  if (status.booking && status.booking.status) {
    events.push(
      new Event(
        EventType.Book,
        parse(status.booking.time || ''),
        `Booked using ${status.booking.message}`,
        status.booking.method,
      ),
    );
  }

  // Email
  if (status.mail.status) {
    const hasOtherThanQueued = !!status.mail.events.find(
      (e: InvoiceMailStatusEvent) => e.event !== 'queued',
    );
    for (const mailEvent of status.mail.events) {
      if (hasOtherThanQueued && mailEvent.event === 'queued') {
        continue;
      }

      // Conversion from UTC to local timezone
      const parsedDate: Date = parse(mailEvent.time);
      const utcDate: Date = new Date(
        Date.UTC(
          parsedDate.getFullYear(),
          parsedDate.getMonth(),
          parsedDate.getDate(),
          parsedDate.getHours(),
          parsedDate.getMinutes(),
          parsedDate.getSeconds(),
        ),
      );

      events.push(
        new Event(
          EventType.Mail,
          parse(utcDate),
          `Email ${mailEvent.event}`,
          mailEvent.recipient,
        ),
      );
    }
  }

  // Collection
  if (status.collection && status.collection.status) {
    // What is a proper title for this?
    events.push(
      new Event(
        EventType.Collection,
        parse(status.collection.date || ''),
        'Collection',
        status.collection.type || undefined,
      ),
    );
  }

  // Reminder
  if (status.reminder && status.reminder.status) {
    status.reminder.time!.split(',').forEach((time, index) => {
      events.push(
        new Event(EventType.Remind, parse(time), `Reminder #${index + 1} sent`),
      );
    });
  }

  // Relation (credit v credited)
  if (status.relation && status.relation.status) {
    events.push(
      new Event(
        EventType.Relation,
        parse(status.relation.date),
        'Credit relation',
        `From ${status.relation.from} to ${status.relation.to}`,
      ),
    );
  }

  for (const [k, { type, title }] of Object.entries(STATUS_TYPE_MAP)) {
    const segment = status[k];
    if (segment && segment.status) {
      events.push(new Event(type, parse(segment.time), title, segment.message));
    }
  }

  events.sort((a: Event, b: Event) => b.time.getTime() - a.time.getTime());

  return events;
}
