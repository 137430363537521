import { EventType } from '@/lib/statusevents/type';

export class Event {
  constructor(
    public type: EventType,
    public time: Date,
    public title: string,
    public detail?: string,
  ) {}

  // Currently supported:
  // green
  // gray
  // dark-gray
  // orange
  // yellow
  // turquoise
  // cyan
  // blue
  // purple
  // red

  get color(): string {
    switch (this.type) {
      case EventType.Mail:
        return 'blue';
      case EventType.Payment:
        return 'green';
      case EventType.Collection:
        return 'red';
      case EventType.Relation:
        return 'dark-gray';
      default:
        return '';
    }
  }

  get icon(): string {
    switch (this.type) {
      case EventType.Mail:
        return 'mail_outline';
      case EventType.Payment:
        return 'euro';
      case EventType.Sepa:
        return 'account_balance';
      case EventType.Recurring:
        return 'event';
      case EventType.Book:
        return 'compare_arrows';
      case EventType.Collection:
        return 'work_outline';
      case EventType.Remind:
        return 'notifications_active';
      case EventType.Relation:
        return 'settings_ethernet';
      default:
        return '';
    }
  }
}
