









































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';

import InvoiceSidebarAction from '@/components/InvoiceSidebarAction.vue';
import CommentTimeline from '@/components/archive/comments/CommentTimeline.vue';
import {
  InvoiceCommentsStatusItem,
  InvoiceStatus,
} from '@/lib/services/invoices';
import StatusTimeline from '@/components/archive/lines/StatusTimeline.vue';
import { Event, generateEventList } from '@/lib/statusevents';
import AttachmentList from '@/components/AttachmentList.vue';

@Component({
  components: {
    AttachmentList,
    StatusTimeline,
    CommentTimeline,
    InvoiceSidebarAction,
    Popover,
  },
})
export default class ViewInvoiceModal extends Vue {
  @Prop({ required: true })
  readonly invoiceId!: string;

  @Prop({ required: true })
  readonly status: InvoiceStatus | null;

  @Prop({ default: true })
  showSidebar: boolean;

  private COMMENT_ELEMENT = 'comment';
  private STATUS_ELEMENT = 'status';
  private ATTACHMENT_ELEMENT = 'attachment';
  public icon = require('@/assets/icons/favicon.png');

  showSidebarDrawer: boolean = false;
  activeElement: string | null = null;
  eventList: Event[] = [];

  showComments(): void {
    if (
      this.activeElement === this.COMMENT_ELEMENT ||
      !this.showSidebarDrawer
    ) {
      this.toggleSidebarDrawer();
    }
    this.activeElement = this.COMMENT_ELEMENT;
  }

  showHistory(): void {
    if (this.activeElement === this.STATUS_ELEMENT || !this.showSidebarDrawer) {
      this.toggleSidebarDrawer();
    }
    this.activeElement = this.STATUS_ELEMENT;
  }

  showAttachments(): void {
    if (
      this.activeElement === this.ATTACHMENT_ELEMENT ||
      !this.showSidebarDrawer
    ) {
      this.toggleSidebarDrawer();
    }
    this.activeElement = this.ATTACHMENT_ELEMENT;
  }

  toggleSidebarDrawer(): void {
    this.showSidebarDrawer = !this.showSidebarDrawer;
  }

  async mounted(): Promise<void> {
    if (this.status !== null) {
      this.eventList = generateEventList(this.status);
    }
  }

  get showAttachmentAction(): boolean {
    return this.status?.attachment?.status ?? false;
  }

  get showCreditAction(): boolean {
    return !['credit', 'credited', 'quotation', 'recurring', 'draft'].includes(
      this.status?.invoice?.state ?? '',
    );
  }

  get showHistoryAction(): boolean {
    return this.eventList.length > 0;
  }

  get drawerTitle(): string | null {
    switch (this.activeElement) {
      case this.COMMENT_ELEMENT:
        return 'archive.other.comments';
      case this.STATUS_ELEMENT:
        return 'archive.other.history';
      case this.ATTACHMENT_ELEMENT:
        return 'archive.actions.attachments';
      default:
        return null;
    }
  }

  get comments(): InvoiceCommentsStatusItem[] {
    if (this.status !== null) {
      return this.status.comments.comments;
    }
    return [];
  }

  get attachments(): Record<'id' | 'name', string>[] {
    if (this.status !== null && this.status.attachment.status) {
      const ids = this.status.attachment?.id?.split(',') ?? [];
      const names = this.status.attachment?.name?.split(',') ?? [];

      return ids.map((id, index) => ({ id, name: names[index] }));
    }
    return [];
  }
}
