

























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { downloadBlob } from '@/lib/download';
import { InvoiceService } from '@/lib/services';
import { getContentType } from '@/lib/helpers';

@Component({})
export default class ViewInvoiceModal extends Vue {
  @Prop({ required: true })
  readonly invoiceId!: string;
  @Prop({ required: true })
  readonly attachments!: Record<'id' | 'name', string>[];

  async previewAttachment(
    attachment: Record<'id' | 'name', string>,
  ): Promise<void> {
    const blob = await InvoiceService.downloadAttachment(
      this.invoiceId,
      attachment.id,
    );
    // We have to recreate the blob, since current type is set to application/octet-stream
    // which will trigger a download as soon as preview is shown
    const previewBlob = new Blob([blob], {
      type: getContentType(attachment.name),
    });
    window.open(URL.createObjectURL(previewBlob), '_blank');
  }

  async downloadAttachment(
    attachment: Record<'id' | 'name', string>,
  ): Promise<void> {
    const blob = await InvoiceService.downloadAttachment(
      this.invoiceId,
      attachment.id,
    );
    downloadBlob(blob, attachment.name ?? undefined);
  }
}
